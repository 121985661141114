
<router-outlet>
    <ngx-spinner [zIndex]="9999999999999"></ngx-spinner>
    <p-confirmDialog #cd header="{{'_Thông báo_' | translate}}" maskStyleClass="confirm-top-zindex" [baseZIndex]="999999999999" icon="pi pi-exclamation-triangle" [transitionOptions]="'0ms'">
        <p-footer>
            <button type="button" pButton icon="pi pi-check" label="{{'_Đồng ý_' | translate}}" (click)="cd.accept()"></button>
            <button type="button" class="p-button-secondary p-button-sm" pButton icon="pi pi-times" label="{{'_Hủy bỏ_' | translate}}" (click)="cd.reject()"></button>
        </p-footer>
    </p-confirmDialog>
    <p-toast position="top-right" [baseZIndex]="999999999999" [autoZIndex]="false"></p-toast>
    <!-- <p-toast position="bottom-center" key="bc"></p-toast> -->
 <icon-control-svg></icon-control-svg>

</router-outlet>

