<!--<div class="fields">-->
<!--  <p-calendar-->
<!--    (onSelect)="onSelectMonth()"-->
<!--    [(ngModel)]="selectBaseQuery"-->
<!--    view="month"-->
<!--    dateFormat="mm/yy"-->
<!--    placeholder="Chọn tháng năm"-->
<!--    [readonlyInput]="true">-->
<!--  </p-calendar>-->
<!--  <span class="uni-icon uni-calendar"> </span>-->
<!--</div>-->
<div class="content-body p-3">

  <!-- row 1 -->
  <!--    <div class="grid">-->
  <!--      <div class="col-2">-->
  <!--        <div class="fields">-->
  <!--          <p-calendar-->
  <!--              (onSelect)="onSelectMonth()"-->
  <!--              [(ngModel)]="selectBaseQuery"-->
  <!--              view="month"-->
  <!--              dateFormat="mm/yy"-->
  <!--              placeholder="Chọn tháng năm"-->
  <!--              [readonlyInput]="true">-->
  <!--          </p-calendar>-->
  <!--          <span class="uni-icon uni-calendar"> </span>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <div class="grid boxs">
    <div class="col-9 md:col-12 lg:col-9 xl:col-9">
      <div class="grid">
        <div class="col-12 md:col-6 lg:col-4 xl:col-3" *ngFor="let tongQuanNhanSu of dashboardData?.tongquan">
          <div class="card">
            <div class="card-content">
              <div class="icon d-flex middle center">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.414 7.02347C8.414 5.07744 10.0196 3.5 12 3.5C13.9804 3.5 15.586 5.07744 15.586 7.02347V7.92546C15.586 9.87149 13.9804 11.4489 12 11.4489C10.0196 11.4489 8.414 9.87149 8.414 7.92546V7.02347Z" fill="#FF7C59"/>
                  <path d="M6.18961 13.2051C6.16381 13.2046 6.13793 13.2043 6.11198 13.2043H3.89606C2.02054 13.2043 0.5 14.6982 0.5 16.5411V17.412C0.5 18.293 1.22686 19.0071 2.12333 19.0071H4.50679V16.8126C4.50679 15.3702 5.15924 14.0781 6.18961 13.2051Z" fill="#FF7C59"/>
                  <path d="M17.8104 13.2051C18.8408 14.0781 19.4932 15.3702 19.4932 16.8126V19.0071H21.8767C22.7731 19.0071 23.5 18.293 23.5 17.412V16.5411C23.5 14.6982 21.9795 13.2043 20.1039 13.2043H17.888C17.8621 13.2043 17.8362 13.2046 17.8104 13.2051Z" fill="#FF7C59"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0362 19.0918V16.8126C18.0362 16.0106 17.7472 15.2746 17.2657 14.6998C16.6445 13.9582 15.703 13.4851 14.6483 13.4851H9.35166C7.47946 13.4851 5.9638 14.976 5.9638 16.8126V19.0918C6.20934 19.906 6.97642 20.5 7.88472 20.5H16.1153C17.0236 20.5 17.7907 19.906 18.0362 19.0918Z" fill="#FF7C59"/>
                  <path d="M5.00402 6.20445C3.49565 6.20445 2.27274 7.4059 2.27274 8.88812V9.5724C2.27274 11.0546 3.49565 12.2561 5.00402 12.2561C6.5124 12.2561 7.73531 11.0546 7.73531 9.5724V8.88812C7.73531 7.4059 6.5124 6.20445 5.00402 6.20445Z" fill="#FF7C59"/>
                  <path d="M16.2647 8.88812C16.2647 7.4059 17.4876 6.20445 18.996 6.20445C20.5044 6.20445 21.7273 7.4059 21.7273 8.88812V9.5724C21.7273 11.0546 20.5044 12.2561 18.996 12.2561C17.4876 12.2561 16.2647 11.0546 16.2647 9.5724V8.88812Z" fill="#FF7C59"/>
                </svg>
              </div>
              <div class="media align-items-stretch">
                <p>{{tongQuanNhanSu?.name | translate}}</p>

                <div class="">
                  <div class="qan d-flex gap12 middle">
                    <div class="d-flex gap12">
                      <h1 class="mb-0">{{ tongQuanNhanSu?.tongnv }}</h1>
                      <label>Người</label>
                    </div>
                    <div class="per"> <span>{{ tongQuanNhanSu?.rateUpDown }} </span></div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!--card-->
        </div>
      </div>
      <div class="grid">
        <div class="col-6 pt-3">
          <div class="chart-section">
            <div class="d-flex bet middle">
              <div class="des">
                <h3 class="">{{'_Số lượng nhân sự_' | translate}}</h3>
                <!-- <p>Công ty KSFinance</p> -->
              </div>

            </div>
            <div class="chart" >
              <!-- <div id="legend-so-luong-nhan-su" class="legend-default">
                  <ul></ul>
              </div> -->
              <p-chart type="bar" [data]="dataChartEmpPosition" [options]="optionsChart"></p-chart>
              <!--                    <canvas style="max-height: 250px" id="so-luong-nhan-su" *ngIf="optionsChart"></canvas>-->
            </div>
          </div>
        </div>
        <div class="col-6 pt-3 ">
          <div class="chart-section">
            <div class="d-flex bet middle">
              <div class="desc">
                <h3 class="mb-3">{{'_Biến động nhân sự_' | translate}} </h3>
                <p>{{ selectedNode?.label }}</p>
              </div>
              <div id="legend-bien-dong-nhan-su" class="legend-default line">
                <ul class="d-flex center"></ul>
              </div>
            </div>
            <div class="chart">
              <canvas style="max-height: 200px" id="bien-dong-nhan-su" *ngIf="dashboardData"></canvas>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="box h2100">
            <h3 class="mb-3">{{'_Thống kê hợp đồng theo loại hợp đồng_' | translate}}</h3>
            <div class="chart d-flex middle">
              <canvas  style="max-height: 248px; max-width: 248px; margin: auto" id="pie" *ngIf="dashboardData"></canvas>
              <div id="legend-doughnut" class="legend-default dou">
                <ul></ul>
              </div>
            </div>
            <ul>
              <li class="d-flex bet">{{'_Hợp đồng sắp hết hạn_' | translate}}: {{ dashboardData?.hd_saphethan }}
                <span class="icon">
                          <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.259459 0.278481C0.54816 0.00352719 1.01624 0.00352719 1.30494 0.278481L5.74054 4.50286C6.02924 4.77781 6.02924 5.2236 5.74054 5.49856L1.30494 9.72293C1.01624 9.99789 0.54816 9.99789 0.259459 9.72293C-0.029243 9.44798 -0.029243 9.00219 0.259459 8.72724L4.17232 5.00071L0.259459 1.27418C-0.029243 0.999223 -0.029243 0.553435 0.259459 0.278481Z" fill="#99A2BC"/>
                          </svg>
                      </span>
              </li>
              <li class="d-flex bet">{{'_Hợp đồng quá hạn_' | translate}}: {{ dashboardData?.hd_quahan }}
                <span class="icon">
                          <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.259459 0.278481C0.54816 0.00352719 1.01624 0.00352719 1.30494 0.278481L5.74054 4.50286C6.02924 4.77781 6.02924 5.2236 5.74054 5.49856L1.30494 9.72293C1.01624 9.99789 0.54816 9.99789 0.259459 9.72293C-0.029243 9.44798 -0.029243 9.00219 0.259459 8.72724L4.17232 5.00071L0.259459 1.27418C-0.029243 0.999223 -0.029243 0.553435 0.259459 0.278481Z" fill="#99A2BC"/>
                          </svg>
                      </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-6">
          <div class="box h-100 ">
            <h3 class="mb-3 flex justify-content-between">
                    <span class="count">
                      {{'_Nhân viên chưa ký hợp đồng_' | translate}} <span class="number-count">{{ dataEmpSignContract.length }}</span>
                    </span>
              <small class="pointer" (click)="routerToNotSignContract()">{{'_Xem thêm_' | translate}}</small>
            </h3>

            <p-dataView #dv [value]="dataEmpSignContract">
              <ng-template let-product pTemplate="listItem">
                <div class="col-12 hover:surface-200">
                  <div class="flex flex-column xl:flex-row xl:align-items-start p-2 gap-4 cursor-pointer">
                    <div class="img" style="height: 0px">
                      <p-avatar [label]="product.nameAvatar" styleClass="mr-2" size="large"
                                [style]="{ 'background-color': '#2196F3', color: '#ffffff' }"  [shape]="'circle'">
                      </p-avatar>
                    </div>
                    <div class="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
                      <div class="flex flex-column align-items-center sm:align-items-start">
                        <div class="font-semibold"><h4>{{ product.full_name }}</h4></div>
                        <div class="flex align-items-center gap-3">
                            <span class="">
                                 <div class=""><span style="color: #b3afaf;">{{'_Ngày hết hạn_' | translate}}</span>: {{ product.contract_expire_dt }}</div>
                                <div class=""><span style="color: #b3afaf;">{{'_Loại hợp đồng_' | translate}}</span>: {{ product.contract_type }}</div>
                            </span>
                        </div>
                      </div>
                      <div class="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
                        <!--                              <h4 class=""><span style="color: #b3afaf;">{{'_Thời hạn_' | translate}}</span>: {{product.duration }}</h4>-->
                        <!--                              <p-button styleClass="p-button-rounded p-button-sm surface-400 text-color hover:surface-700 hover:text-white" label="Chi tiết" icon="pi pi-eye"></p-button>-->
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </p-dataView>

            <!--                  <app-list-grid-angular  -->
            <!--                    *ngIf="empSignContractColumnDef.length > 0"-->
            <!--                    [listsData]="dataEmpSignContract" -->
            <!--                    [height]="heightGrid" -->
            <!--                    [idGrid]="'dataEmpSignContract'"-->
            <!--                    [columnDefs]="empSignContractColumnDef">-->
            <!--                  </app-list-grid-angular>-->
            <div class="p-6 text-center" *ngIf="dataEmpSignContract && dataEmpSignContract.length < 1">
              <p style="opacity: 0.6;">{{'_Không có dữ liệu_' | translate}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3 md:col-12 lg:col-3 xl:col-3" style="margin-bottom: 25px;">
      <div class="box mb-3 snnv pt-0 pl-0 pr-0" style="min-height: 45%;padding-bottom: 0px;">

        <p-calendar [(ngModel)]="dateBirthdays" [inline]="true"  class="max-w-full">
          <ng-template pTemplate="date" let-date>
            <!--              <p-avatarGroup styleClass="mb-3" *ngIf="date.day ===13">-->
            <!--                <p-avatar image="https://primefaces.org/cdn/primeng/images/demo/avatar/amyelsner.png" size="large" shape="circle"></p-avatar>-->
            <!--                <p-avatar image="https://primefaces.org/cdn/primeng/images/demo/avatar/asiyajavayant.png" size="large" shape="circle"></p-avatar>-->
            <!--              </p-avatarGroup>-->
            <!--              <span [ngStyle]="{textDecoration: (date.day < 21 && date.day > 10) ? 'line-through' : 'inherit'}">{{date.day}}</span>-->
            <span  [ngClass]="{'pi pi-gift': birthDays.indexOf(date.day) > -1 }">{{date.day}}</span>
          </ng-template>
          <ng-template pTemplate="header">
            <h3 class="flex justify-content-between">
                    <span class="count">
                      {{'_Sinh nhật nhân viên_' | translate}} <span class="number-count">{{ dashboardData?.nv_sinhnhat }}</span>
                    </span>
              <small class="pointer mr-2" (click)="viewAllBirthday()">{{'_Xem thêm_' | translate}}</small>
            </h3>
          </ng-template>
          <ng-template pTemplate="footer">
            <p-avatarGroup styleClass="mb-3" *ngIf="empBirthDays.length > 0">
              <ng-container *ngFor="let item of empBirthDays">
                <p-avatar pTooltip="{{item.full_name}} {{item.birthday}}" tooltipPosition="bottom" *ngIf="item?.islink" image="{{item.avatar_url}}" size="large" shape="circle"></p-avatar>
                <p-avatar pTooltip="{{item.full_name}} {{item.birthday}}" tooltipPosition="bottom" *ngIf="!item?.islink" [label]="item.avatar_url" size="large" shape="circle"
                          [style]="{ 'background-color': '#2196F3', color: '#ffffff' }" >
                </p-avatar>
              </ng-container>
            </p-avatarGroup>
          </ng-template>
        </p-calendar>
        <!--          <ul class="birth-day" *ngIf="empBirthDays && empBirthDays.length > 0">-->
        <!--            <li *ngFor="let birthDay of empBirthDays" class="mb-2">-->
        <!--              <div class="d-flex gap12">-->
        <!--                <div><img src="{{ birthDay.avatar_url ? birthDay.avatar_url : '../../../../../assets/images/avatar.jpg' }}"></div>-->
        <!--                <div>-->
        <!--                  <div><label>{{'_Họ tên_' | translate}}: </label>{{ birthDay.full_name }}</div>-->
        <!--                  <div><label>{{'_Ngày sinh_' | translate}}: </label>{{ birthDay.birth_day }}</div>-->
        <!--                  <div><label>{{'_Tuổi_' | translate}}: </label>{{ birthDay.age }}</div>-->
        <!--                  <div style="color: #9c9b9b;font-size: 12px;line-height: 1;"><label></label>{{ birthDay.birth_day_ago }}</div>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </li>-->
        <!--          </ul>-->
      </div>
      <div class="box" style="height: 49ch;">
        <!--          <h3 class="mb-3 flex justify-content-between">-->
        <!--            <span class="count">-->
        <!--                       Nhân viên sắp hết hạn hợp đồng <span class="number-count">{{ listDataView.length }}</span>-->
        <!--            </span>-->
        <!--&lt;!&ndash;            <small class="pointer" (click)="routerExpireContract()">Xem thêm</small>&ndash;&gt;-->
        <!--          </h3>-->
        <p-tabView [(activeIndex)]="tabIndex" (onChange)="handleChange($event.index)">
          <p-tabPanel>
            <ng-template pTemplate="header">
              <i class="uni-icon uni-user-group"></i>
              <span class="ml-2 count">{{'_Sắp hết hạn hợp đồng_' | translate}} <span class="number-count">{{ dataEmpContractExp?.length }}</span></span>
            </ng-template>
          </p-tabPanel>
          <p-tabPanel>
            <ng-template pTemplate="header">
              <i class="uni-icon uni-user-ban"></i>
              <span class="ml-2 count">{{'_Sắp hết hạn bổ nhiệm_' | translate}}<span class="number-count">{{ empProcessExpire?.length }}</span></span>
            </ng-template>
          </p-tabPanel>
        </p-tabView>
        <p-dataView #dv [value]="listDataView">
          <ng-template let-product pTemplate="listItem">
            <div class="col-12 overflow-auto hover:surface-200">
              <div class="flex flex-column xl:flex-row xl:align-items-start p-2 gap-4">
                <!--                  <div class="img">-->
                <!--                    <img class="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round" src="/assets/images/no-image2.png" [alt]="product.full_name" />-->
                <!--                  </div>-->
                <div class="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
                  <div class="flex flex-column align-items-center sm:align-items-start">
                    <div class="font-semibold"><h4>{{ product.full_name }}</h4></div>
                    <div class="flex align-items-center gap-3">
                            <span class="">
                                 <div class=""><span style="color: #b3afaf;">{{'_Ngày hết hạn_' | translate}}</span>: {{ product.contract_expire_dt }}</div>
                              <div class=""><span style="color: #b3afaf;">{{'_Loại hợp đồng_' | translate}}</span>: {{ product.contract_type }}</div>
                              <div class="mb-1"><span style="color: #b3afaf;">{{'_Thời hạn_' | translate}}</span>: {{ product.duration }}</div>
                              <p-button styleClass="p-button-rounded p-button-sm surface-400 text-color hover:surface-700 hover:text-white" label="Chi tiết" icon="pi pi-eye"></p-button>
                              <p-button (click)="routerExpireContract()" styleClass="p-button-rounded p-button-sm surface-400 text-color hover:surface-700  hover:surface-700 hover:text-white ml-2" label="Xem thêm" icon="pi pi-list"></p-button>
                            </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </p-dataView>
        <!--          <app-list-grid-angular-->
        <!--            *ngIf="empContractExpColumnDef.length > 0"-->
        <!--            [listsData]="listDataView"-->
        <!--            [height]="heightGrid"-->
        <!--            [idGrid]="'listDataView'"-->
        <!--            [columnDefs]="empContractExpColumnDef">-->
        <!--          </app-list-grid-angular>-->
        <div class="p-6 text-center" *ngIf="listDataView && listDataView.length < 1">
          <p style="opacity: 0.6;">{{'_Không có dữ liệu_' | translate}}</p>
        </div>
      </div>
      <!--        <div class="box mb-3">-->
      <!--          <h3 class="mb-3 flex justify-content-between">-->
      <!--            <span class="count">-->
      <!--                     Nhân viên hết hạn hộ chiếu/chứng minh thư <span class="number-count">{{ dataEmpIdCardExp.length }}</span>-->
      <!--            </span>-->
      <!--&lt;!&ndash;            <small class="pointer" (click)="routerExpireContract()">Xem thêm</small>&ndash;&gt;-->
      <!--          </h3>-->
      <!--          <p-dataView #dv [value]="dataEmpIdCardExp">-->
      <!--            <ng-template let-product pTemplate="listItem">-->
      <!--              <div class="col-12">-->
      <!--                <div class="flex flex-column xl:flex-row xl:align-items-start pt-2 pb-2 gap-4">-->
      <!--                  <div class="img">-->
      <!--                    <img class="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round" src="/assets/images/no-image2.png" [alt]="product.full_name" />-->
      <!--                  </div>-->
      <!--                  <div class="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">-->
      <!--                    <div class="flex flex-column align-items-center sm:align-items-start">-->
      <!--                      <div class="font-semibold"><h4>{{ product.full_name }}</h4></div>-->
      <!--                      <div class="flex align-items-center gap-3">-->
      <!--                            <span class="">-->
      <!--                                 <div class=""><span style="color: #b3afaf;">Ngày hết hạn</span>: {{ product.idcard_expire_dt }}</div>-->
      <!--                                <div class=""><span style="color: #b3afaf;">Loại giấy tờ</span>: {{ product.idcard_type }}</div>-->
      <!--                                <div class=""><span style="color: #b3afaf;">Thời hạn</span>: {{ product.duration }}</div>-->
      <!--                                <p-button styleClass="p-button-rounded p-button-sm surface-400" label="Chi tiết" icon="pi pi-eye"></p-button>-->
      <!--                            </span>-->
      <!--                      </div>-->
      <!--                    </div>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </ng-template>-->
      <!--          </p-dataView>-->
      <!--&lt;!&ndash;          <app-list-grid-angular&ndash;&gt;-->
      <!--&lt;!&ndash;            *ngIf="empIdCardExpColumnDef.length > 0"&ndash;&gt;-->
      <!--&lt;!&ndash;            [listsData]="dataEmpIdCardExp"&ndash;&gt;-->
      <!--&lt;!&ndash;            [height]="heightGrid"&ndash;&gt;-->
      <!--&lt;!&ndash;            [idGrid]="'dataEmpIdCardExp'"&ndash;&gt;-->
      <!--&lt;!&ndash;            [columnDefs]="empIdCardExpColumnDef">&ndash;&gt;-->
      <!--&lt;!&ndash;          </app-list-grid-angular>&ndash;&gt;-->
      <!--          <div class="p-6 text-center" *ngIf="dataEmpIdCardExp && dataEmpIdCardExp.length < 1">-->
      <!--            <p style="opacity: 0.6;">Không có dữ liệu</p>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!-- <div class="chart" style="max-width: 300px; margin: auto">
        <p-chart type="doughnut" [data]="dataDou" [style]="{'width': '40%'}"></p-chart>
      </div> -->
      <!-- <div class="chart">
        <canvas  style="max-width: 180px; margin: auto" id="doughnut-2" *ngIf="dashboardData"></canvas>
        <div id="legend-doughnut-2" class="legend-default dou">
          <ul></ul>
      </div>
      </div> -->
    </div>
  </div>

  <!--  <div class="grid boxs pb-6">-->
  <!--    <div class="col-3 md:col-12 lg:col-3 xl:col-3">-->
  <!--    </div>-->
  <!--    <div class="col-6 md:col-12 lg:col-6 xl:col-6">-->
  <!--      <div id="funnel"></div>-->
  <!--    </div>-->
  <!--    <div class="col-3 md:col-12 lg:col-3 xl:col-3">-->
  <!--    </div>-->
  <!--  </div>-->

</div>




<p-sidebar
  styleClass="hr-diagram"
  [style]="{ width: '75%' }"
  [(visible)]="isHrDiagram"
  position="right">
  <ng-template pTemplate="header">
    <!-- #iconheader --></ng-template>
  <ng-template pTemplate="footer"></ng-template>
  <p-organizationChart [value]="listAgencyMap" #mydiv  [preserveSpace]="false" selectionMode="single" [(selection)]="selectedNode" (onNodeSelect)="onNodeSelect($event)"
                       styleClass="company">
    <ng-template let-node pTemplate="person">
      <div class="node-header p-corner-top">{{node.data.position ? node.data.position : 'No position'}}</div>
      <div class="node-content">
        <img src="{{node.data.avatarUrl ? node.data.avatarUrl : '../../../../../assets/images/img_avatar.png'}}" width="32">
        <div>{{node.data.full_name ? node.data.full_name : 'No name'}}</div>
      </div>
    </ng-template>
    <ng-template let-node pTemplate="department" >
      {{node.label}}
    </ng-template>

  </p-organizationChart>
</p-sidebar>
