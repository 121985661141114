
<p-toolbar>
  <div class="p-toolbar-group-left">
    <a (click)="goToHome()" class="go-to-home">
      <img style="max-height: 30px;" src="../../../assets/images/logo1.png">
    </a>
  </div>
  <div class="flex gap12 end col-6 middle p-0 justify-content-end">
    <div class="p-toolbar-group-right profile">
        <div class="card flex align-items-center">
           <span class="item flag d-flex middle gap6" (click)="opsetting.toggle($event)">
            <i class="uni-icon uni-setting"></i>
          </span>
          <span class="item flag d-flex middle gap6" (click)="op.toggle($event)">
            <i class="uni-icon uni-language-fill"></i>
          </span>
          <span class="noti item">
            <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 19.75C9.1 19.75 10 18.85 10 17.75H6C6 18.85 6.89 19.75 8 19.75ZM14 13.75V8.75C14 5.68 12.36 3.11 9.5 2.43V0.25H6.5V2.43C3.63 3.11 2 5.67 2 8.75V13.75L0 15.75V16.75H16V15.75L14 13.75Z" fill="#212633"/>
            </svg>
          </span>
          <p-avatar  image="../../../assets/images/avatar.jpg" size="large"></p-avatar>
          <span  class="ml-12 mr-15 name" (click)="menu.toggle($event)"> {{userName}} &nbsp;
            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.59 0.294922L6 4.87492L1.41 0.294922L0 1.70492L6 7.70492L12 1.70492L10.59 0.294922Z" fill="#212633" />
            </svg>
          </span>
          <p-menu #menu [popup]="true" [model]="items" styleClass="my-account-menu"></p-menu>
      </div>
    </div>
  </div>
</p-toolbar>
<p-sidebar
    [style]="{ width: '40%' }"
    [(visible)]="isShowChangePassword"
    [appendTo]="'body'"
    position="right">
    <ng-template pTemplate="header">
        <!-- #iconheader -->
        Cập nhật mật khẩu tài khoản [{{userName}}] </ng-template>
    <ng-template pTemplate="footer"></ng-template>
    <form #editSMSF="ngForm" class="form-horizontal change-password">
      <div class="box-body" style="padding:10px;">
        <div class="form-row" style="margin:10px 0px;">
          <label class="col-sm-4 control-label">Mật khẩu
            <span style="color:red">*</span>
          </label>
          <div class="col-sm-8">
            <div class="input-group">
              <input type="{{isShowPass ? 'text' : 'password'}}" class="form-control" name="userPassword" autocomplete="off" (change)="checkPasswordcf()" [(ngModel)]="modelPass.userPassword" required
                value="" #userPassword="ngModel" placeholder="Password">
                <span class="pi pi-eye-pass {{ isShowPass ? 'pi-eye-slash' : 'pi-eye' }} " (click)="isShowPass = !isShowPass"></span><div *ngIf="submitPass && !modelPass.userPassword " class="alert-validation alert-danger">
                <div *ngIf="!modelPass.userPassword">
                  Password bắt buộc nhập!
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row" style="margin:10px 0px;">
          <label class="col-sm-4 control-label">Nhập lại mật khẩu
            <span style="color:red">*</span>
          </label>
          <div class="col-sm-8">
            <div class="input-group">
              <input type="{{isShowRepass ? 'text' : 'password'}}" class="form-control" name="userPassCf" autocomplete="off"  (change)="checkPasswordcf()" [(ngModel)]="modelPass.userPassCf" required
                #userPassCf="ngModel" id="userPassCf" placeholder="Nhập lại Password">
                <span class="pi pi-eye-pass {{ isShowRepass ? 'pi-eye-slash' : 'pi-eye' }}" (click)="isShowRepass = !isShowRepass"></span>
                <div *ngIf="submitPass && (confimPassword || !modelPass.userPassCf) " class="alert-validation alert-danger">
                  <div *ngIf="!modelPass.userPassCf || confimPassword">
                    Nhập lại mật khẩu không đúng
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div class="form-row" style="margin:10px 0px;">
          <div class="col-md-12 text-right">
            <p-button label="Lưu lại" styleClass="mr-1" (click)="saveChangePass()"></p-button>
            <p-button label="Hủy" styleClass="p-button-secondary" (click)="isShowChangePassword = !isShowChangePassword"></p-button>
          </div>

        </div>
      </div>

    </form>
</p-sidebar>

<p-sidebar
    [style]="{ width: '410px' }"
    [(visible)]="displayActiveAccount"
    [appendTo]="'body'"
    position="right">
    <ng-template pTemplate="header">
        <!-- #iconheader -->
        Kích hoạt tài khoản [{{userName}}] </ng-template>
    <form #editSMSF="ngForm" class="form-horizontal change-password">
      <div class="grid">
        <div class="col-12">
          <div class="fields">
            <label>Mật khẩu
              <span style="color:red">*</span>
            </label>
              <input type="{{isShowPass ? 'text' : 'password'}}" class="form-control" maxlength="6" name="userPassword" autocomplete="off" (change)="checkPasswordcf()" [(ngModel)]="modelPass.userPassword" required
              value="" #userPassword="ngModel" placeholder="Nhập  6 ký tự số">
              <span class="pi pi-eye-pass {{ isShowPass ? 'pi-eye-slash' : 'pi-eye' }} " (click)="isShowPass = !isShowPass"></span><div *ngIf="submitPass && !modelPass.userPassword " class="alert-validation alert-danger">
              <div *ngIf="!modelPass.userPassword">
                Password bắt buộc nhập!
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="fields">
            <label>Nhập lại mật khẩu
              <span style="color:red">*</span>
            </label>
            <input type="{{isShowRepass ? 'text' : 'password'}}" class="form-control" maxlength="6" name="userPassCf" autocomplete="off"  (change)="checkPasswordcf()" [(ngModel)]="modelPass.userPassCf" required
              #userPassCf="ngModel" id="userPassCf" placeholder="Nhập lại 6 ký tự là số">
              <span class="pi pi-eye-pass {{ isShowRepass ? 'pi-eye-slash' : 'pi-eye' }}" (click)="isShowRepass = !isShowRepass"></span>
              <div *ngIf="submitPass && (confimPassword || !modelPass.userPassCf) " class="alert-validation alert-danger">
                <div *ngIf="!modelPass.userPassCf || confimPassword">
                  Nhập lại mật khẩu không đúng
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="grid" style="margin:10px 0px;">
        <div class="col-12 text-right">
          <p-button label="Lưu lại" styleClass="mr-1" (click)="saveActive()"></p-button>
          <p-button label="Hủy" styleClass="p-button-secondary" (click)="displayActiveAccount = !displayActiveAccount"></p-button>
        </div>
      </div>
    </form>
    <ng-template pTemplate="footer"></ng-template>
</p-sidebar>

<p-sidebar
    [style]="{ width: '410px' }"
    [(visible)]="displayActive"
    [appendTo]="'body'"
    position="right">
    <ng-template pTemplate="header">
        <!-- #iconheader -->
        Kích hoạt tài khoản </ng-template>
      <div class="fields">
          <label for="">Nhập mã code</label>
          <input type="text" [(ngModel)]="modelOTP.code" name="code"  class="form-control">
      </div>
    <ng-template pTemplate="footer">
      <p-button label="Kích hoạt" styleClass="p-button-sm mr-2" (onClick)="handleFillEvent()" icon="pi pi-check"></p-button>
      <p-button label="Gửi lại OTP" styleClass="p-button-sm" (onClick)="activeAccount()" icon="pi pi-reload"></p-button>
    </ng-template>
</p-sidebar>

<p-overlayPanel #op styleClass="choose-language" [style]="{'z-index': 99999}">
  <ng-template pTemplate="content">
    <h3>{{"select_language" | translate}}</h3>
    <ul class="list-lang">
      <li class="active" [ngClass]="{'active': translate.currentLang === 'vi'}" (click)="translate.use('vi');op.toggle($event);saveLang('vi')">
        <div class="d-flex middle gap-2">
          <span class="uni-icon uni-flag-vn"></span>
          <span>{{"vietnamese" | translate}}</span>
          <span class="checked" *ngIf="translate.currentLang === 'vi'">
                <i class="uni-icon uni-vector uni-blue-color"></i>
              </span>
        </div>
      </li>
      <li (click)="translate.use('en');op.toggle($event);saveLang('en')" [ngClass]="{'active': translate.currentLang === 'en'}">
        <div class="d-flex middle gap-2">
          <span class="uni-icon uni-flag-en"></span>
          <span>{{"english" | translate}}</span>
          <span class="checked" *ngIf="translate.currentLang === 'en'">
                <i class="uni-icon uni-vector uni-blue-color"></i>
              </span>
        </div>
      </li>
    </ul>
  </ng-template>
</p-overlayPanel>
<p-overlayPanel #opsetting styleClass="choose-language" [style]="{'z-index': 99999}">
  <ng-template pTemplate="content">
    <h3>Cài đặt</h3>
    <ul class="list-lang">
      <li class="active" [ngClass]="{'active': isHorizontal}" (click)="saveSettingMenu('horizontal')">
        <div class="d-flex middle gap-2">
          <span class="pi pi-arrows-h"></span>
          <span>Horizontal</span>
          <span class="checked" *ngIf="isHorizontal">
                <i class="uni-icon uni-vector uni-blue-color"></i>
              </span>
        </div>
      </li>
      <li (click)="saveSettingMenu('vertical')" [ngClass]="{'active': !isHorizontal}">
        <div class="d-flex middle gap-2">
          <span class="pi pi-arrows-v"></span>
          <span>Vertical</span>
          <span class="checked" *ngIf="!isHorizontal">
                <i class="uni-icon uni-vector uni-blue-color"></i>
              </span>
        </div>
      </li>
    </ul>
  </ng-template>
</p-overlayPanel>
