import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError, of} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AuthService} from './auth.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {MessageService} from 'primeng/api';
import {ErrorService} from './error.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService, private router: Router,
              private errorService: ErrorService,
              private translate: TranslateService,
              private messageService: MessageService,
              private spinner: NgxSpinnerService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let handled = false;
    if (!request.url.includes('https://chat.sunshineapp.co')) {
      request = request.clone({
        setHeaders: {
          'X-Role-Token': localStorage.hasOwnProperty('md5')
          && localStorage.getItem('md5')
            ? localStorage.getItem('md5') : '',
          'Accept-Language': this.translate.currentLang ? this.translate.currentLang : 'vi',
        }
      });
    } else {
      const tokenlogin = localStorage.hasOwnProperty('loginTokenChat')
      && localStorage.getItem('loginTokenChat')
        ? JSON.parse(localStorage.getItem('loginTokenChat'))
        : '';
      request = request.clone({
        setHeaders: {
          'X-Auth-Token': tokenlogin !== '' ? tokenlogin.authToken : '',
          'Accept-Language': this.translate.currentLang ? this.translate.currentLang : 'vi',
          'X-User-Id': tokenlogin !== '' ? tokenlogin.userId : '',
          // 'Content-Type': 'application/json',
        }
      });

    }
    return next.handle(request)
      .pipe(
        retry(1),
        catchError((returnedError) => {
          let errorMessage = null;
          this.errorService.setStocks(returnedError);

          if (returnedError.error instanceof ErrorEvent) {
            this.errorService.setStocks(returnedError.error.message);
            errorMessage = `Error: ${returnedError.error.message}`;
            this.messageService.add({
              severity: 'error'
              , summary: 'Thông báo'
              , detail: `Error: ${returnedError.error.message}`
            });
          } else if (returnedError instanceof HttpErrorResponse) {
            //   errorMessage = `Error Status
            //   ${returnedError.status}: ${returnedError.error.error} - ${returnedError.error.message}`;
            handled = this.handleServerSideError(returnedError);
          }
          console.error(errorMessage ? errorMessage : returnedError);

          if (!handled) {
            if (errorMessage) {
              return throwError(errorMessage);
            } else {
              return throwError('Unexpected problem occurred');
            }
          } else {
            return of(returnedError);
          }
        })
      );
  }

  private handleServerSideError(error: HttpErrorResponse): boolean {
    let handled = false;
    this.errorService.setStocks(error.status);
    switch (error.status) {
      case 401:
        this.auth.logout();
        this.router.navigate(['/login']);
        this.spinner.hide();
        handled = true;
        break;
      case 403:
        this.messageService.add({
          severity: 'error',
          summary: 'Thông báo',
          detail: `Error: Quyền truy cập bị từ chối. Vui lòng liên hệ đến Quản trị viên!`
        });
        this.spinner.hide();
        handled = true;
        break;
      case 500:
        this.messageService.add({severity: 'error', summary: 'Thông báo', detail: `Error: Lỗi Server !`});
        this.spinner.hide();
        handled = true;
        break;
      case 404:
        this.messageService.add({severity: 'error', summary: 'Thông báo', detail: `Error: Lỗi đường dẫn !`});
        this.spinner.hide();
        handled = true;
        break;
      case 0:
        this.messageService.add({severity: 'error', summary: 'Thông báo', detail: `Error: Lỗi Server !`});
        this.spinner.hide();
        handled = true;
        break;
    }

    return handled;
  }
}
