import {AfterViewChecked, AfterViewInit, Component, inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {ApiHrmService} from 'src/app/services/api-hrm/apihrm.service';
import {Chart} from 'chart.js';
import {TreeNode} from 'primeng/api';
import {NgxSpinnerService} from 'ngx-spinner';
import {finalize, takeUntil} from 'rxjs';
import * as moment from 'moment';
import {AgGridFn} from 'src/app/common/function-common/common';
import {cloneDeep} from 'lodash';
import {TranslateService} from '@ngx-translate/core';
import D3Funnel from 'd3-funnel';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})

export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
  dateBirthdays: Date | undefined;
  public translate = inject(TranslateService);
  bodyClasses = 'skin-blue sidebar-mini';
  body: HTMLBodyElement = document.getElementsByTagName('body')[0];
  columnDefs = [];
  columnDefs1 = [];
  months: any = [];
  years: any = [];
  basicData: any;
  basicData2: any;
  basicOptions: any;
  basicOptions2: any;
  dataDou: any;

  organs: any = [];

  // nsMoi: nhan su moi,
  dashboardData: any = null;
  dataChartEmpPosition: any = null;
  detailDashboardEmployee: any = null;
  selectedMonth: any = {name: 'Tháng ' + (moment().month() + 1), code: (moment().month() + 1)};
  selectedYear: any = {
    name: 2022,
    code: 2022
  };
  queryDashboard = {
    orgid: '',
    months: (moment().month() + 1),
    years: 2022
  };
  currentYear = moment().year();
  bg = [
    '#22c55e',
    '#3b82f6',
    '#e9b208',
    '#FFA384',
    '#36eb44',
    '#beeb36',
    '#eb3679',
    '#eb36d5',
    '#7c36eb',
    '#36daeb',
    '#36ebb9',
    '#36eb6e',
  ];
  baseQuery = {
    months: 0,
    years: 0
  };

  selectBaseQuery = new Date(moment(new Date(new Date().getFullYear(), new Date().getMonth())).format());
  // line chart
  chartOptions = {
    responsive: true
  };

  theOrginSelected: any = {};
// line chart end
// bar chart
  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true
  };


  heightGrid = 200;
// hợp đồng hết hạn
  colsEmpContractExp = [];
  empContractExpColumnDef = [];
  dataEmpContractExp = [];

// chưa ký hợp đồng
  colsEmpSignContract = [];
  empSignContractColumnDef = [];
  dataEmpSignContract = [];

// hết hạn hộ chiếu/cccd
  colsEmpIdCardExp = [];
  empIdCardExpColumnDef = [];
  dataEmpIdCardExp = [];

// sinh nhật
  empBirthDays: any[] = [{
    'avatar_url': '',
    'full_name': '',
    'age': '',
    'birth_day': '',
    'birth_day_ago': '',
    'islink': false
  }];

  nhansu_in = [
    {
      'months': '2018',
      'tongnv': 300,
      'thang': 2018
    },
    {
      'months': '2019',
      'tongnv': 200,
      'thang': 2019
    },
    {
      'months': '2020',
      'tongnv': 330,
      'thang': 2020
    },
    {
      'months': '2021',
      'tongnv': 500,
      'thang': 2021
    },
    {
      'months': '2022',
      'tongnv': 270,
      'thang': 2022
    },
    {
      'months': '2023',
      'tongnv': 240,
      'thang': 2023
    },
  ];

  nhansu_out = [
    {
      'months': '2018',
      'tongnv': 10,
      'thang': 2018
    },
    {
      'months': '2019',
      'tongnv': 40,
      'thang': 2019
    },
    {
      'months': '2020',
      'tongnv': 153,
      'thang': 2020
    },
    {
      'months': '2021',
      'tongnv': 100,
      'thang': 2021
    },
    {
      'months': '2022',
      'tongnv': 50,
      'thang': 2022
    },
    {
      'months': '2023',
      'tongnv': 120,
      'thang': 2023
    },
  ];

  dataEmpByYears = [
    {
      gd: '1',
      name: '2018',
      emp_num: 300,
      emp_total: 1000
    },
    {
      gd: '2',
      name: '2019',
      emp_num: 200,
      emp_total: 1000
    },
    {
      gd: '3',
      name: '2020',
      emp_num: 330,
      emp_total: 1000
    }, {
      gd: '4',
      name: '2021',
      emp_num: 500,
      emp_total: 1000
    },
    {
      gd: '5',
      name: '2022',
      emp_num: 270,
      emp_total: 1000
    },
    {
      gd: '6',
      name: '2023',
      emp_num: 200,
      emp_total: 1000
    }
  ];


  listAgencyMap: TreeNode[];
  selectedNode;
  detailOrganizeMap = null;
  orgId = '';
  isHrDiagram = false;
  tabIndex: number = 0;
  listDataView: any[] = [];
  empProcessExpire: any = [];

  statuses = [{'label': 'Unregistered', 'value': 30}, {'label': 'Working', 'value': 32}, {'label': 'Hold', 'value': 7}, {
    'label': 'Order',
    'value': 25
  }, {'label': 'Not Returning', 'value': 5}];
  public funnel;
  optionsChart: any;
  birthDays: number[] = [];

  constructor(
    private HomeService: ApiHrmService,
    private route: ActivatedRoute,
    private apiService: ApiHrmService,
    private spinner: NgxSpinnerService,
    private router: Router) {
  }

  ngOnInit() {
    this.onSelectMonth();
    this.getDashboardEmployee();
  }

  public ngAfterViewInit() {
    // this.drawFunnelChart(this.statuses);
  }

  ngOnDestroy() {

  }

  getOriginLabelByid() {
    this.theOrginSelected = this.organs.filter(d => d.value === this.queryDashboard.orgid);
  }

  onChartClick(event) {
    console.log(event, this.queryDashboard.orgid);
  }

  getDashboardEmployee() {
    this.spinner.show();
    this.apiService.getDashboardEmployee(this.baseQuery)
      .pipe(
        finalize(() => this.spinner.hide())
      )
      .subscribe(
        (results: any) => {
          console.log(results.data);
          this.detailDashboardEmployee = results.data;
          this.onInitChart();
          this.spinner.hide();
        },
        error => {
          this.spinner.hide();
        });
  }

  onInitChart() {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    this.dataChartEmpPosition = {
      labels: this.dataEmpByYears.map(d => `${d.name} (${d.emp_num})`),
      datasets: [
        {
          label: this.translate.instant('_Vị trí_'),
          data: this.dataEmpByYears.map(d => d.emp_num),
          backgroundColor: [
            'rgba(255, 159, 64, 0.8)',
            'rgba(75, 192, 192, 0.8)',
            'rgba(54, 162, 235, 0.8)',
            'rgba(153, 102, 255, 0.8)',
            'rgba(60, 179, 113, 0.8)',
            'rgba(255, 156, 48, 0.8)',
            'rgba(255, 156, 251, 0.8)',
            'rgba(106, 90, 205, 0.8)',
            'rgba(255, 99, 71, 0.8)',
            'rgba(120, 120, 120, 0.8)',
            'rgba(234, 156, 120, 0.8)',
            'rgba(255, 156, 59, 0.8)',
            'rgba(42, 156, 59, 0.8)',
            'rgba(42, 14, 59, 0.8)',
          ],
          borderColor: [
            'rgb(255, 159, 64)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(60, 179, 113)',
            'rgb(255, 156, 48)',
            'rgb(255, 156, 251)',
            'rgb(106, 90, 205)',
            'rgb(255, 99, 71)',
            'rgb(120, 120, 120)',
            'rgb(234, 156, 120)',
            'rgb(255, 156, 59)',
            'rgb(42, 156, 59)',
            'rgb(42, 14, 59)',
          ],
          borderWidth: 1
        }
      ]
    };
    this.optionsChart = {
      plugins: {
        legend: {
          labels: {
            color: textColor
          },
          display: false
        }
      },
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        },
        x: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        }
      }
    };
  }

  getDashboardRemind() {
    this.apiService.getDashboardRemind(this.baseQuery)
      .pipe(
        finalize(() => this.spinner.hide())
      )
      .subscribe(results => {
        if (results.status === 'success') {

          this.colsEmpContractExp = results.data.gridEmpContractExp;
          this.dataEmpContractExp = results.data.dataEmpContractExp;
          this.listDataView = cloneDeep(results.data.dataEmpContractExp);

          this.colsEmpSignContract = results.data.gridEmpSignContract;
          if (results.data.dataEmpSignContract && results.data.dataEmpSignContract.length > 0) {
            this.dataEmpSignContract = results.data.dataEmpSignContract.map(item => {
              return { ...item, nameAvatar: item.full_name ? this.getStringName(item.full_name) : ''};
            });
          }

          this.colsEmpIdCardExp = results.data.gridEmpIdCardExp;
          this.dataEmpIdCardExp = results.data.dataEmpIdCardExp;

          if (results.data.empBirthDays.length > 0) {
            const dateBirthDays = results.data.empBirthDays.map(item => {
              const births = item.birth_day ? item.birth_day.split('/') : [];
              return {
                birth_day: births.length > 0 ? parseFloat(births[0]) : null,
                birthday: item.birth_day,
                full_name: item.full_name,
                avatar_url: item.avatar_url ? item.avatar_url : this.getStringName(item.full_name),
                islink: item.avatar_url ? true : false
              };
            });
            this.empBirthDays = dateBirthDays;
            this.dateBirthdays = new Date(this.baseQuery.years, this.baseQuery.months - 1, new Date().getDay());
            this.birthDays = dateBirthDays.map(item => item.birth_day);
          }
          this.initGrid();
        }
      });
  }

  getStringName(name: string) {
    const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

    let initials: any = [...name.matchAll(rgx)] || [];

    initials = (
      (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
    ).toUpperCase();
    return initials;
  }

  initGrid() {
    this.empContractExpColumnDef = [...AgGridFn(this.colsEmpContractExp.filter((d: any) => !d.isHide)),
      {
        headerName: '',
        filter: '',
        width: 100,
        pinned: 'right',
        cellRenderer: 'buttonAgGridComponent',
        cellClass: ['border-right', 'no-auto'],
        cellRendererParams: (params: any) => this.showButtons(params),
        checkboxSelection: false,
        field: 'checkbox'
      }];
    // this.empSignContractColumnDef = [
    //   ...AgGridFn(this.colsEmpSignContract.filter((d: any) => !d.isHide)), {
    //   headerName: '',
    //   filter: '',
    //   width: 100,
    //   pinned: 'right',
    //   cellRenderer: 'buttonAgGridComponent',
    //   cellClass: ['border-right', 'no-auto'],
    //   cellRendererParams: (params: any) => this.showButtons(params),
    //   checkboxSelection: false,
    //   field: 'checkbox'
    // }];
    this.empIdCardExpColumnDef = [...AgGridFn(this.colsEmpIdCardExp.filter((d: any) => !d.isHide))];
  }

  showButtons(event: any) {
    return {
      buttons: [
        {
          onClick: this.handleViewProfile.bind(this),
          label: 'Xem',
          icon: 'fa fa-eye',
          class: 'btn-primary'
        }
      ]
    };
  }

  handleViewProfile(event) {
    this.router.navigateByUrl(`/nhan-su/ho-so-nhan-su/chi-tiet-ho-so-nhan-su?empId=${event?.rowData?.empId}&tab=3`);
  }

  routerToNotSignContract() {
    this.router.navigateByUrl(`/nhan-su/xu-ly-hop-dong-chua-ky`);
  }

  routerExpireContract() {
    this.router.navigateByUrl(`/nhan-su/xu-ly-hop-dong-het-han`);
  }

  onSelectMonth() {
    const months = moment(this.selectBaseQuery).month();

    const years = moment(this.selectBaseQuery).year();
    this.baseQuery.months = months + 1;
    this.baseQuery.years = years;
    this.getDashboardInfo();
    this.getDashboardRemind();
  }

  chartSlNhanSu() {
    const configs = {
      type: 'bar',
      canvasID: 'so-luong-nhan-su',
      options: {
        responsive: true,
        indexAxis: 'x',
        plugins: {
          // htmlLegend: {
          //   containerID: 'legend-so-luong-nhan-su',
          // },
          legend: {
            display: false,
          },
        },

      }
    };
    const labels = this.dashboardData.nhansuthang.map(month => {
      return month.months;
    });
    const value = this.dashboardData.nhansuthang.map(tongnv => {
      return tongnv.tongnv;
    });

    const datas = {
      labels: labels,
      datasets: [{
        label: 'Số lượng nhân sự',
        data: value,
        backgroundColor: [
          '#f68c1f',
        ],
        borderWidth: 1,
        cornerRadius: 20,
        barThickness: 40,
        borderRadius: 4,
        borderSkipped: false,
      }]


    };
    this.drawChart(configs, datas);
  }

  chartBDNhanSu() {
    const configs = {
      type: 'line',
      canvasID: 'bien-dong-nhan-su',
      options: {
        responsive: true,
        // indexAxis: 'x',
        plugins: {
          htmlLegend: {
            containerID: 'legend-bien-dong-nhan-su',
          },
          legend: {
            display: false,
          },
        },

      }
    };

    const labelsNsIn = this.nhansu_in.map(month => {
      return month.months;
    });
    const valueNsIn = this.nhansu_in.map(tongnv => {
      return tongnv.tongnv;
    });

    const labelsNsOut = this.nhansu_out.map(month => {
      return month.months;
    });
    const valueNsOut = this.nhansu_out.map(tongnv => {
      return tongnv.tongnv;
    });

    const datas = {
      labels: labelsNsIn,
      datasets: [
        {
          label: 'Tiếp nhận',
          data: valueNsIn,
          backgroundColor: [
            '#0a58ca',
          ],
          borderWidth: 1,
          cornerRadius: 20,
          barThickness: 40,
          borderRadius: 4,
          borderSkipped: false,
        },
        {
          label: 'Nghỉ việc',
          data: valueNsOut,
          backgroundColor: [
            '#DA100B',
          ],
          borderWidth: 1,
          cornerRadius: 20,
          barThickness: 40,
          borderRadius: 4,
          borderSkipped: false,
        },
      ]


    };
    this.drawChart(configs, datas);
  }

  charDou() {
    const configs = {
      type: 'pie',
      canvasID: 'pie',
      options: {
        responsive: true,
        indexAxis: 'x',
        plugins: {
          htmlLegend: {
            containerID: 'legend-doughnut',
          },
          legend: {
            display: false,
          },
        },

      }
    };
    const labels = [];
    const bg = [];
    for (let i = 0; i < this.dashboardData.nhansu_hd.length; i++) {
      labels.push(this.dashboardData.nhansu_hd[i].name);
      bg.push(this.bg[i]);
    }
    const value = this.dashboardData.nhansu_hd.map(tongnv => {
      return tongnv.tongnv;
    });
    const datas = {
      labels: labels,
      datasets: [{
        label: '',
        data: value,
        backgroundColor: [
          'rgba(255, 159, 64, 0.8)',
          'rgba(75, 192, 192, 0.8)',
          'rgba(54, 162, 235, 0.8)',
          'rgba(153, 102, 255, 0.8)',
          'rgba(60, 179, 113, 0.8)',
          'rgba(255, 156, 48, 0.8)',
          'rgba(255, 156, 251, 0.8)',
          'rgba(106, 90, 205, 0.8)',
          'rgba(255, 99, 71, 0.8)',
          'rgba(120, 120, 120, 0.8)',
          'rgba(234, 156, 120, 0.8)',
          'rgba(255, 156, 59, 0.8)',
          'rgba(42, 156, 59, 0.8)',
          'rgba(42, 14, 59, 0.8)',
        ],
        borderWidth: 1,
        cornerRadius: 20,
        barThickness: 40,
        borderRadius: 4,
        borderSkipped: false,
      }]


    };
    this.drawChart(configs, datas);
  }

  charDou2() {
    const configs = {
      type: 'doughnut',
      canvasID: 'doughnut-2',
      options: {
        responsive: true,
        indexAxis: 'x',
        plugins: {
          htmlLegend: {
            containerID: 'legend-doughnut-2',
          },
          legend: {
            display: false,
          },
        },

      }
    };
    const labels = [];
    const bg = [];
    for (let i = 0; i < this.dashboardData.nhansu_sex.length; i++) {
      labels.push(this.dashboardData.nhansu_sex[i].name);
      bg.push(this.bg[i]);
    }
    const value = this.dashboardData.nhansu_sex.map(tongnv => {
      return tongnv.tongnv;
    });
    const datas = {
      labels: labels,
      datasets: [{
        label: '',
        data: value,
        backgroundColor: bg,
        borderWidth: 1,
        cornerRadius: 20,
        barThickness: 40,
        borderRadius: 4,
        borderSkipped: false,
      }]


    };
    this.drawChart(configs, datas);
  }

  load() {
  }

  /*
    configs: type, options(htmlLegend), canvasID
    data:
  */
  drawChart(configs, datas) {
    setTimeout(() => {
      let ctx: any = document.getElementById(configs.canvasID);
      console.log(document.getElementById(configs.canvasID))
      ctx = ctx?.getContext('2d');
      const chart = new Chart(ctx, {
        type: configs.type,
        data: datas,
        options: configs.options,
        plugins: [{
          id: 'htmlLegend',
          afterUpdate(chart: any, args, options: any) {
            if (configs.options.plugins.htmlLegend?.containerID) {
              const legendContainer = document.getElementById(configs.options.plugins.htmlLegend.containerID);
              let listContainer: any = legendContainer.querySelector('ul');
              if (!listContainer) {
                listContainer = document.createElement('ul');
                listContainer.style.display = 'flex';
                listContainer.style.flexDirection = 'row';
                listContainer.style.margin = 0;
                listContainer.style.padding = 0;
                legendContainer.appendChild(listContainer);
              }
              const ul = listContainer;

              // Remove old legend items
              while (ul.firstChild) {
                ul.firstChild.remove();
              }

              const items = chart.options.plugins.legend.labels.generateLabels(chart);
              items.forEach((item: any) => {
                const li = document.createElement('li');
                const {type} = chart.config;
                li.onclick = () => {
                  if (type === 'pie' || type === 'doughnut') {
                    // Pie and doughnut charts only have a single dataset and visibility is per item
                    chart.toggleDataVisibility(item.index);
                  } else {
                    chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                  }
                  chart.update();
                };

                // Color box
                const boxSpan = document.createElement('span');
                boxSpan.style.background = item.fillStyle;
                // Text
                const textContainer: any = document.createElement('span');
                textContainer.style.textDecoration = item.hidden ? 'line-through' : '';
                let numberValue = '';
                if (type === 'pie' || type === 'doughnut') {
                  if (chart.config.data.datasets.length === 1) {
                    numberValue += ' (' + new Intl.NumberFormat().format(chart.config.data.datasets[0].data[item.index]) + ')';
                  }
                }
                const text = document.createTextNode(item.text + numberValue);
                textContainer.appendChild(text);
                li.appendChild(boxSpan);
                li.appendChild(textContainer);
                ul.appendChild(li);
              });
            }
          }
        }],
      });
      chart.update();

      // chart.destroy();
    }, 500);
  }

  hrDiagram() {
    this.spinner.show();
    this.selectedNode = null;
    this.listAgencyMap = [];
    this.isHrDiagram = true;
    // this.getAgencyOrganizeMap(true);
  }

  getAgencyOrganizeMap(type = false) {
    this.apiService.getAgencyOrganizeMap().subscribe(results => {
      if (results.status === 'success') {
        this.spinner.hide();
        this.listAgencyMap = [...results.data.root];
        if (localStorage.getItem('organize') === null) {
          this.selectedNode = this.listAgencyMap[0];
          this.detailOrganizeMap = this.selectedNode;
          localStorage.setItem('organize', JSON.stringify(this.listAgencyMap[0]));
          this.queryDashboard.orgid = this.selectedNode.orgId;
          this.getOriginLabelByid();
          // this.query.org_level = this.selectedNode.org_level;
          this.load();
        } else {
          // this.selectedNode = JSON.parse(localStorage.getItem("organize"));
          this.queryDashboard.orgid = this.selectedNode.orgId;
          this.getOriginLabelByid();
          // this.parseObjectProperties(this.listAgencyMap, this.selectedNode.organizeId);
          this.detailOrganizeMap = this.selectedNode;
          if (type) {
            this.isHrDiagram = true;
          }
          this.load();
        }
        this.getDashboardInfo();
      }
    });
  }

  onNodeSelect(event) {
    this.detailOrganizeMap = event.node;
    this.isHrDiagram = false;
    this.queryDashboard.orgid = this.detailOrganizeMap.orgId;
    localStorage.setItem('organize', JSON.stringify(this.detailOrganizeMap));
    this.getDashboardInfo();
  }

  getDashboardInfo() {

    this.getOriginLabelByid();
    this.dashboardData = null;
    this.apiService.getDashboardInfo(this.baseQuery)
      .pipe(
        finalize(() => this.spinner.hide())
      )
      .subscribe(results => {
        if (results.status === 'success') {
          this.dashboardData = results.data;
          this.chartSlNhanSu();
          this.chartBDNhanSu();
          this.charDou();
          // this.charDou2();
        }
      });
  }

  // select month

  changeMonths(e) {
    this.queryDashboard.months = e.value.code;
    this.spinner.show();
    this.getDashboardInfo();
  }

// select year
  changeYears(e) {
    this.queryDashboard.years = e.value.code;
    this.spinner.show();
    this.getDashboardInfo();
  }

  handleChange(index: number) {
    this.tabIndex = index;
    if (this.tabIndex === 1) {
      this.listDataView = [];
      this.empProcessExpire = [];
    } else {
      this.listDataView = cloneDeep(this.dataEmpContractExp);
    }

  }

  viewAllBirthday() {
    this.router.navigateByUrl('/nhan-su/danh-sach-sinh-nhat');
  }

  private drawFunnelChart(data, options?): void {
    if (!options) {
      options = {
        chart: {
          bottomPinch: 2
        },
        block: {
          dynamicHeight: true,
          highlight: true,
          minHeight: 15,
        },
      };
    }

    this.funnel = new D3Funnel('#funnel');
    this.funnel.draw(data, options);
  }

}
