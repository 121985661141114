import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormatTimePipe, NavbarComponent } from './navbar/navbar.component';
import { ToggleFullscreenDirective } from './directives/toggle-fullscreen.directive';
import { ButtonRendererComponent } from '../utils/common/button-renderer.component';
import { NormalButtonRendererComponent } from '../utils/common/normal-button-renderer.component';
import { ButtonRendererComponent1 } from '../utils/common/button-renderer.component-1';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ButtonRendererMutiComponent } from '../utils/common/button-renderermutibuttons.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { MarginPipe } from './margin.pipe';
import { MarginRoundPipe } from './margin-round.pipe';
import { NumericEditor } from '../utils/common/numeric-editor.component';
import { AgGridModule } from '@ag-grid-community/angular';
import {CalendarModule} from 'primeng/calendar';
import {PaginatorModule} from 'primeng/paginator';
import {MenubarModule} from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { AvatarModule } from 'primeng/avatar';
import { ToolbarModule } from 'primeng/toolbar';
import {AvatarFullComponent, AvatarImageComponent, RatingAGComponent} from '../common/ag-component/avatarFull.component';
import { ButtonAgGridComponent } from '../common/ag-component/button-renderermutibuttons.component';
import { CustomTooltipComponent } from '../common/ag-component/customtooltip.component';
import { CurrencyFormatPipeModule } from '../common/pipe/currency-pipe.module';
import {DialogModule} from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SidebarModule } from 'primeng/sidebar';
import { ToastModule } from 'primeng/toast';
import {ImageModule} from 'primeng/image';
import {TranslateModule} from '@ngx-translate/core';
import { PanelMenuModule } from 'primeng/panelmenu';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import {RatingModule} from 'primeng/rating';
import { HeaderCareerComponent } from './header-career/header-career.component';
import { FooterCareerComponent } from './footer-career/footer-career.component';
import { CareerNewsComponent } from '../components/career/career-news/career-news.component';
@NgModule({
    exports: [
        CommonModule,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        ToggleFullscreenDirective,
        ButtonRendererComponent,
        NormalButtonRendererComponent,
        ButtonRendererComponent1,
        ButtonRendererMutiComponent,
        AvatarFullComponent,
        RatingAGComponent,
        AvatarImageComponent,
        MarginPipe,
        MarginRoundPipe,
        NumericEditor,
        ButtonAgGridComponent,
        CustomTooltipComponent,
        FormatTimePipe,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        FormatTimePipe,
        HeaderCareerComponent,
        FooterCareerComponent,
        CareerNewsComponent,
    ],
    imports: [
        RouterModule,
        CommonModule,
        SplitButtonModule,
        FormsModule,
        DropdownModule,
        CalendarModule,
        PaginatorModule,
        AvatarModule,
        MenubarModule,
        ToolbarModule,
        CurrencyFormatPipeModule,
        MenuModule,
        DialogModule,
        MultiSelectModule,
        OverlayPanelModule,
        SidebarModule,
        ToastModule,
        ImageModule,
        AgGridModule.withComponents([
            ButtonRendererComponent,
            CustomTooltipComponent,
            ButtonRendererMutiComponent,
            ButtonRendererComponent1,
            NumericEditor
          ]),
        TranslateModule,
        PanelMenuModule,
        RatingModule
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        ToggleFullscreenDirective,
        ButtonRendererComponent,
        NormalButtonRendererComponent,
        ButtonRendererComponent1,
        AvatarFullComponent,
        ButtonRendererMutiComponent,
        MarginPipe,
        MarginRoundPipe,
        NumericEditor,
        ButtonAgGridComponent,
        FormatTimePipe,
        CustomTooltipComponent,
        FooterComponent,
        NavbarComponent,
        SidebarComponent,
        AvatarImageComponent,
        RatingAGComponent,
        HeaderCareerComponent,
        FooterCareerComponent,
        CareerNewsComponent,
    ],
    entryComponents: [
    ]
})
export class SharedModule { }
