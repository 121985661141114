import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'child-avatar',
  template: `
    <div *ngIf="isImage">
      <p-image *ngIf="value" src="{{value}}" [appendTo]="'body'" [style]="{'display': 'flex'}" alt="Image" width="20"
               [preview]="true"></p-image>
      <p-image *ngIf="!value" src="/assets/images/file-jpg.png" [appendTo]="'body'" [style]="{'display': 'flex'}" alt="Image"
               width="20"></p-image>
    </div>
    <div *ngIf="!isImage">
      <p-avatar image="{{value}}" [style]="{'display': 'flex'}" size="large" [shape]="isCircle ? 'circle' : ''"
                styleClass="d-flex"></p-avatar>
    </div>
  `
})
export class AvatarFullComponent implements OnInit {
  value: string;
  isImage = true;
  isCircle = true;

  constructor() {
  }

  agInit(params: any) {
    this.value = params.value;
    if (params.colDef.cellClass && params.colDef.cellClass.length > 0) {
      this.isCircle = params.colDef.cellClass.indexOf('square') > -1 ? false : true;
    } else {
      this.isCircle = true;
    }
    if (params.data && params.data.hasOwnProperty('back_url') && params.data.hasOwnProperty('front_url')) {
      this.isImage = false;
    } else {
      this.isImage = true;
    }
  }

  ngOnInit() {
  }
}


@Component({
  selector: 'child-avatar',
  template: `
    <div *ngIf="isImage">
      <ng-container *ngIf="params.data && params.data?.full_name; else addName">
        <p-image *ngIf="value" src="{{value}}" [appendTo]="'body'" [style]="{'display': 'flex'}" alt="Image" width="20"
                 [preview]="true"></p-image>
        <!--      <p-image *ngIf="!value" src="/assets/images/file-jpg.png" [appendTo]="'body'" [style]="{'display': 'flex'}" alt="Image"-->
        <!--               width="20"></p-image>-->

        <p-avatar *ngIf="!value" [label]="noImage" styleClass="mr-2" size="large"
                  [style]="{ 'background-color': '#2196F3', color: '#ffffff' }"  [shape]="isCircle ? 'circle' : ''">
        </p-avatar>
        {{name}}
        <span *ngIf="is_new" class="noti-number bg-info">Mới</span>
      </ng-container>
      <ng-template #addName>
       <i class="uni-icon uni-plus-cicle"></i> <span class="text-primary-500 pointer hover:text-primary-900">Chưa giao</span>
      </ng-template>

    </div>
    <div *ngIf="!isImage">
      <p-avatar image="{{value}}" [style]="{'display': 'flex'}" size="large" [shape]="isCircle ? 'circle' : ''"
                styleClass="d-flex"></p-avatar>
    </div>
  `
})
export class AvatarImageComponent implements OnInit {
  value: string;
  noImage: string;
  name: string;
  isImage = true;
  is_new = true;
  isCircle = true;
  params: any;
  constructor() {
  }

  agInit(params: any) {
    this.params = params;
    this.value = params.value;
    this.noImage = params.data &&  params.data.full_name ? this.getStringName(params.data.full_name) : 'NN';
    this.is_new = params.data &&  params.data.is_new === 1 ? true : false;
    this.name = params.data &&  params.data.full_name ?  params.data.full_name :  'No name';
    if (params.colDef.cellClass && params.colDef.cellClass.length > 0) {
      this.isCircle = params.colDef.cellClass.indexOf('square') > -1 ? false : true;
    } else {
      this.isCircle = true;
    }
    if (params.data && params.data.hasOwnProperty('back_url') && params.data.hasOwnProperty('front_url')) {
      this.isImage = false;
    } else {
      this.isImage = true;
    }
  }

  ngOnInit() {
  }

  getStringName(name: string) {
    const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

    let initials: any = [...name.matchAll(rgx)] || [];

    initials = (
      (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
    ).toUpperCase();
    return initials;
  }
}



@Component({
  selector: 'rating',
  template: `
    <p-rating [(ngModel)]="value" [cancel]="false"></p-rating>

  `
})
export class RatingAGComponent implements OnInit {
  value: number = 0;
  params: any;
  constructor() {
  }

  agInit(params: any) {
    this.params = params;
    this.value = params.value || 0;
  }

  ngOnInit() {
  }

}


