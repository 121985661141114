import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Login } from 'src/app/models/login.model';
import { environment } from 'src/environments/environment';
import { AUTH_CONSTANT } from '../containers/constant';
import { parseJwt } from '../utils/fn';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  options = {
    headers: new HttpHeaders({
      // Authorization: this._authService.getAuthorizationHeaderValue(),
      'Content-Type': 'application/json',
      'x-api-key': 'pgH7QzFHJx4w46fI~5Uzi4RvtTwlEXp'
    })
  };

  constructor(
    private http: HttpClient) {
  }
  login(params: Login): Observable<any> {
    return this.http.post(environment.apiHrmBase + '/api/v1/userlogin/LoginTokenResult', params, this.options);
  }


  // refreshToken(refreshToken: string): Observable<any> {
  //   const urlencoded = new URLSearchParams();
  //   urlencoded.append('client_id', 'swagger_development');
  //   urlencoded.append('grant_type', 'refresh_token');
  //   urlencoded.append('scope', 'openid');
  //   urlencoded.append('refresh_token', refreshToken);
  //   return this.http.post(`${environment.authBase}/auth/realms/usee/protocol/openid-connect/token`, urlencoded);
  // }

  loginSuccess(data: any): Observable<any> {
    // localStorage.setItem(AUTH_CONSTANT.ID_TOKEN, data.id_token || '');
    localStorage.setItem(AUTH_CONSTANT.EXPIRES_IN, `${data.expires_in}` || '');
    // localStorage.setItem(AUTH_CONSTANT.REFRESH_TOKEN, data.refresh_token || '');
    // localStorage.setItem(AUTH_CONSTANT.REFRESH_TOKEN_BY_IN, data.refresh_expires_in || '');
    localStorage.setItem(AUTH_CONSTANT.SCOPE, data.scope || '');
    // localStorage.setItem(AUTH_CONSTANT.SESSION_STATE, data.session_state || '');
    localStorage.setItem(AUTH_CONSTANT.TOKEN, data.access_token || '');
    localStorage.setItem(AUTH_CONSTANT.USER_NAME, data.user_name || '');
    localStorage.setItem(AUTH_CONSTANT.TOKENBIZZONE, data.access_token || '');
    return of(true);
  }

  logout(): void {
    localStorage.removeItem(AUTH_CONSTANT.ID_TOKEN);
    localStorage.removeItem(AUTH_CONSTANT.EXPIRES_IN);
    localStorage.removeItem(AUTH_CONSTANT.REFRESH_TOKEN);
    localStorage.removeItem(AUTH_CONSTANT.REFRESH_TOKEN_BY_IN);
    localStorage.removeItem(AUTH_CONSTANT.SCOPE);
    localStorage.removeItem(AUTH_CONSTANT.SESSION_STATE);
    localStorage.removeItem(AUTH_CONSTANT.TOKEN);
    localStorage.removeItem(AUTH_CONSTANT.TOKENBIZZONE);
    localStorage.clear();
  }

  expireToken(): boolean {
    const token = localStorage.getItem(AUTH_CONSTANT.TOKENBIZZONE);
    if (token) {
      const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
      return (Math.floor((new Date()).getTime() / 1000)) >= expiry;
    }
    return true;
  }

  getToken(): string | null {
    return localStorage.getItem(AUTH_CONSTANT.TOKENBIZZONE) ? 'Bearer ' + localStorage.getItem(AUTH_CONSTANT.TOKENBIZZONE) : '' ;
  }

  getRefreshToken(): string | null {
    return localStorage.getItem(AUTH_CONSTANT.REFRESH_TOKEN);
  }

  getExpireIn(): string | null {
    return localStorage.getItem(AUTH_CONSTANT.EXPIRES_IN);
  }
  getIdToken(): string | null {
    return localStorage.getItem(AUTH_CONSTANT.ID_TOKEN);
  }
  getAuthorizationHeaderValue(): string | null {
    return localStorage.getItem(AUTH_CONSTANT.TOKENBIZZONE) ? 'Bearer ' + localStorage.getItem(AUTH_CONSTANT.TOKENBIZZONE) : null;
  }


  getSessionState(): string | null {
    return localStorage.getItem(AUTH_CONSTANT.SESSION_STATE);
  }

  getUserName(): string {
    const tokenData = parseJwt(localStorage.getItem(AUTH_CONSTANT.USER_NAME));
    return tokenData.user_name || 'User Name';
  }

  getUserId(): string {
    const tokenData = parseJwt(localStorage.getItem(AUTH_CONSTANT.TOKENBIZZONE));
    return tokenData.sub || 'User Name';
  }
}
