import {Component, OnInit} from '@angular/core';
import {UserManager} from 'oidc-client';
import {filter} from 'rxjs/operators';
import {ApiService} from 'src/app/services/api.service';
import {AuthService} from 'src/app/services/auth.service';
import {environment} from 'src/environments/environment';
import {Event, RouterEvent, Router, NavigationEnd} from '@angular/router';
import queryString from 'query-string';
import {MessageService} from 'primeng/api';
import {NgxSpinnerService} from 'ngx-spinner';
import {isEqual, first, compact, intersection} from 'lodash';

declare var $: any;

@Component({
  // moduleId: module.id,
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {
  menuItems: any[] = [];
  listmenuChecks = [];
  isWarning = 0;
  isShowheader = true;
  pathname: string;
  isHorizontal: boolean = true;
  constructor(
    private router: Router,
    private authService: AuthService,
    private apiService: ApiService,
    private messageService: MessageService,
    private spinner: NgxSpinnerService,
  ) {
    if (localStorage.hasOwnProperty('setting_menu') && localStorage.getItem('setting_menu') != null) {
      const setting_menu: string  = localStorage.getItem('setting_menu');
      console.log(setting_menu)
      this.isHorizontal = setting_menu === 'horizontal' ? true : false;
    } else {
      this.isHorizontal = true;
    }
    const pathname = window.location.pathname;
    this.pathname = pathname;
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((e: any) => {
      if (this.router.url && this.router.url.includes('chat-ui')) {
        this.isShowheader = false;
      } else {
        this.isShowheader = true;
      }
      if (this.menuItems.length > 0) {
        this.parseObjectProperties(this.menuItems, e.url);
        this.menuItems = [...this.menuItems];
        localStorage.setItem('menuItems', JSON.stringify(this.menuItems));
      } else {
        this.getListMenuByUserId(pathname);
      }
    });
  }


  getListMenuByUserId(pathname) {
    const queryMeny = queryString.stringify({userId: null, webId: 'a8c20532-39b8-409c-8dda-aefa95e103be'});
    this.apiService.clientMenuGetListByUserId(queryMeny).subscribe(results => {
      this.menuItems = results.data.filter(menuItem => menuItem);
      this.parseObjectProperties(this.menuItems, pathname);
      localStorage.setItem('menuItems', JSON.stringify(results.data));
      this.menuItems = [...this.menuItems];
    });
  }


  async ngOnInit() {

  }

  convetArry(datas) {
    for (const item of datas) {
      this.listmenuChecks.push(item);
      if (item.submenus && item.submenus.length > 0) {
        this.convetArry(item.submenus);
      }
    }
  }

  parseObjectProperties(obj: any[], pathname, indexP = 1) {
    obj.forEach((k, index) => {
      k.label = k.title;
      k.styleClass = 'parent_no_active' + ' ' + k.classs;
      if (k.path && k.classs !== 'navigation-header') {
        k.routerLink = k.path;
        k.icon = '';
        k.label = `${indexP}.${index + 1}. ${k.title}`  ;
      }
      if (k.path && k.classs === 'nav-link') {
        k.routerLink = k.path;
      }
      if (k.submenus && k.submenus.length > 0) {
        k.items = k.submenus.filter((d: any) => d.classs && (d.classs.indexOf('hidden') < 0));
      }
      if (k.routerLink) {
        // active menu con

        if (k.isExternalLink) {
          if (k.routerLink && pathname.includes(k.routerLink)) {
            k.styleClass = 'parent_active' + ' ' + k.classs;
          } else {
            k.styleClass = 'parent_no_active' + ' ' + k.classs;
          }
        } else {
          const newpathname = pathname ? compact(pathname.split('/')) : [];
          const newrouterLink = k.routerLink ? compact(k.routerLink.split('/')) : [];
          if(newpathname.length <= newrouterLink.length) {
            if (k.routerLink && pathname === k.routerLink) {
              k.styleClass = k.classs + ' active' + ' ' + k.classs;
              k.icon = '';
            } else {
              k.styleClass = k.classs + ' no-active' + ' ' + k.classs;
              k.icon = '';
            }
          } else {
            if (k.routerLink && isEqual(intersection(newpathname, newrouterLink), newrouterLink)) {
              k.styleClass = k.classs + ' active' + ' ' + k.classs;
              k.icon = '';
            } else {
              k.styleClass = k.classs + ' no-active' + ' ' + k.classs;
              k.icon = '';
            }
          }
        }

      } else {
        if (k.submenus && k.submenus.length > 0) {
          const items = k.submenus.filter(i => i.path && pathname.includes(i.path));
          if (items.length > 0) {
            k.styleClass = 'parent_active' + ' ' + k.classs;
            k.expanded = true;
          }
        } else {
          if (k.path === '/home') {
            k.routerLink = k.path;
            k.icon = 'uni-icon uni-home-building uni-white-color uni-hover-orange';
            k.isExternalLink = true;
          }
        }
      }

      if (k.hasOwnProperty('items') && Array.isArray(k.items) && k.items.length > 0) {
        this.parseObjectProperties(k.items, pathname, index + 1);
      }
    });
  }

  findNodeInTree2(list, nodeId, element1): any {
    for (let i = 0; i < list.length; i++) {
      if (list[i].path === nodeId) {
        element1.columnValue = list[i].badgeClass;
        break;
      } else if (Array.isArray(list[i].submenus) && list[i].submenus.length) {
        this.findNodeInTree2(list[i].submenus, nodeId, element1);
      }
    }
  }

  private manager: UserManager = new UserManager(environment.authenSettings);

}
